@import '../utilities/lib.less';

div.dialogWrapper {
	.bas-gradient (#fff;
		#eee);

	&.previewSwitch {
		.tabRowContainer {
			>div.tab {
				background: @PrimaryLayoutingColor;
				color: @SecondaryFontColor;

				&.active {
					background: @SecondaryLayoutingColor;
					color: @PrimaryFontColor;
				}
			}
		}

		.tabbody {
			.iframePreviewContainer {
				&.tablet {
					div.image {
						background: url('../../img/preview/preview_tablet_small.png') no-repeat;
						background-size: contain;
					}
				}

				&.mobile {
					div.image {
						background: transparent url('../../img/preview/preview_mobile_small.png') no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}

	.marginContainer {
		height: 24px;
	}

	div.closeDialog,
	div.destroyDialog {
		&.button {
			padding-right: 8px;
			margin-right: 0;
		}
	}

	.dialogHeader {
		background: @PrimaryCiColor;
		min-height: 33px;

		.destroyDialog,
		.closeDialog {
			top: 6px;
			right: 6px;

			.iconContainer {
				background-color: @PrimaryCiColor;
			}

			&:hover .iconContainer {
				background-color: @HoverColor;
			}
		}

		h3 {
			background: transparent;
			color: @SecondaryFontColor;
			font-size: 16px;
			padding: 10px;
		}
	}

	.dialogContainer {
		float: none;
		border: none;
		padding: 15px 20px;
		background: transparent;

		.dialogContent {
			margin-top: 0;

			>.bas-formSection:first-child {
				margin-top: 0;
			}

			.item.horizontal>.itemBox {
				padding-right: 0;
			}

			.field {
				&.noLabel {
					display: block;
					padding-left: 0;
				}

				&.buttonline {
					padding: 15px 0 0 0;

					.button {
						margin-bottom: 0;
					}
				}
			}
		}

		.component.notification {
			.text_notification {
				margin-top: 0;
			}

			.icon_notification {
				padding: 0;
				padding-right: 36px;
			}
		}
	}

	.dialogFooter {
		background: transparent;
		padding: 0 15px;

		.dialogButton {
			position: relative;
			.defaultButton ();

			&.highlight {
				.highlightButton ();
			}
		}
	}

	&.browsersupportDialog {
		-webkit-box-shadow: 10px 10px 86px -16px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 10px 10px 86px -16px rgba(0, 0, 0, 0.75);
		box-shadow: 10px 10px 86px -16px rgba(0, 0, 0, 0.75);

		.dialogContainer .dialogContent .field {
			&.noLabel {
				padding: 0;
			}

			&.buttonline {
				padding: 0;
				margin: 20px 0 0 0;

				.button {
					margin-bottom: 0;
				}

				.itemBox {
					padding-right: 0;
				}
			}
		}

		.dialogContainer {
			float: none;
			padding: 0;
			border: none;
			filter: none;

			.dialogContent {
				margin-top: 0;

				.welcomeScreenContent {
					padding: 45px 45px 20px 45px;
					max-width: 400px;
					overflow: auto;

					.container {
						&.left {
							display: inline-block;

							>img {
								width: 101px;
								height: 93px;
								float: left;
								margin-right: 30px;
							}
						}

						&.right {
							display: inline-block;
							width: 250px;

							.headline {
								font-size: 18px;
								margin-bottom: 10px;
							}

							.content {
								line-height: 19px;
							}
						}
					}
				}
			}
		}

		.dialogFooter {
			text-align: center;
			padding: 10px;
			padding-bottom: 20px;
			display: block;

			.dialogButton {
				position: relative;
				.defaultButton ();

				&.highlight {
					.highlightButton ();
				}
			}
		}
	}

	&.browsersupportDialog {
		.dialogContainer {
			padding-bottom: 15px;

			.dialogContent {
				.browserSupportScreenContent {
					.container.left {
						margin-right: 30px;
					}

					.container.right {
						width: 340px;

						.downloadList {
							margin-top: 20px;
							margin-bottom: 0;
							list-style-position: inside;
							list-style-image: url('../../../../../customer/base/mr/img/icons/list_icon_arrow.png');
						}
					}
				}
			}
		}
	}
}